import * as React from "react";
import { Typography, Box, Stack, Button, Grid } from "@mui/material";

// icons
import Link from "../utils/link";
import Section from "../utils/section";
import theme from "../utils/theme";

const Personalizza = () => {
    return (
        <Section maxWidth="md" background={theme.palette.grey[900]}>
            <Stack spacing={2} textAlign="center">
                <Typography variant="h3" component="h2">
                    Vuoi una grafica personalizzata?
                </Typography>
                <Typography>
                    Realizziamo <strong>salviette</strong> e <strong>bavagli</strong> personalizzati in base alle tue esigenze. Tutti i nostri prodotti sono
                    personalizzabili con il logo e con la tua grafica.
                </Typography>

                <Box>
                    <Link type="internal" href="/personalizza-prodotti" title="Scopri di più sulla personalizzazione">
                        <Button size="large" variant="contained">
                            Scopri la personalizzazione
                        </Button>
                    </Link>
                </Box>
            </Stack>
        </Section>
    );
};

export default Personalizza;
